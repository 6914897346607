import { createApp, createSSRApp, nextTick } from "vue";
import "#build/paths.mjs";
import "#build/fetch.mjs";
import { applyPlugins, createNuxtApp } from "./nuxt.js";
import { createError } from "./composables/error.js";
import "#build/css";
import plugins from "#build/plugins";
import RootComponent from "#build/root-component.mjs";
import { vueAppRootContainer } from "#build/nuxt.config.mjs";
let entry;
if (import.meta.server) {
  entry = async function createNuxtAppServer(ssrContext) {
    const vueApp = createApp(RootComponent);
    const nuxt = createNuxtApp({ vueApp, ssrContext });
    try {
      await applyPlugins(nuxt, plugins);
      await nuxt.hooks.callHook("app:created", vueApp);
    } catch (error) {
      await nuxt.hooks.callHook("app:error", error);
      nuxt.payload.error = nuxt.payload.error || createError(error);
    }
    if (ssrContext?._renderResponse) {
      throw new Error("skipping render");
    }
    return vueApp;
  };
}
if (import.meta.client) {
  if (import.meta.dev && import.meta.webpackHot) {
    import.meta.webpackHot.accept();
  }
  let vueAppPromise;
  entry = async function initApp() {
    if (vueAppPromise) {
      return vueAppPromise;
    }
    const isSSR = Boolean(
      window.__NUXT__?.serverRendered || document.getElementById("__NUXT_DATA__")?.dataset.ssr === "true"
    );
    const vueApp = isSSR ? createSSRApp(RootComponent) : createApp(RootComponent);
    const nuxt = createNuxtApp({ vueApp });
    async function handleVueError(error) {
      await nuxt.callHook("app:error", error);
      nuxt.payload.error = nuxt.payload.error || createError(error);
    }
    vueApp.config.errorHandler = handleVueError;
    try {
      await applyPlugins(nuxt, plugins);
    } catch (err) {
      handleVueError(err);
    }
    try {
      await nuxt.hooks.callHook("app:created", vueApp);
      await nuxt.hooks.callHook("app:beforeMount", vueApp);
      vueApp.mount(vueAppRootContainer);
      await nuxt.hooks.callHook("app:mounted", vueApp);
      await nextTick();
    } catch (err) {
      handleVueError(err);
    }
    if (vueApp.config.errorHandler === handleVueError) {
      vueApp.config.errorHandler = void 0;
    }
    return vueApp;
  };
  vueAppPromise = entry().catch((error) => {
    console.error("Error while mounting app:", error);
    throw error;
  });
}
export default (ssrContext) => entry(ssrContext);
